


























































































































import {
  Component, Vue,
} from 'vue-property-decorator';
import AllianzDepositRecurringConfirmationViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/allianz-deposit-recurring-confirmation-view-model';
import RecurringDepositResult
  from '@/vue-app/components/allianz-dashboard/deposits/recurring_deposits/RecurringDepositResult.vue';

@Component({ name: 'RecurringDepositConfirmation' })
export default class RecurringDepositConfirmation extends Vue {
  recurring_confirmation_view_model = Vue.observable(
    new AllianzDepositRecurringConfirmationViewModel(this),
  );

  $refs!: {
    recurring_deposit_result: RecurringDepositResult;
  }

  nextStep() {
    this.$refs.recurring_deposit_result.recurring_deposit_result_view_model.setCollectionDay(
      this.recurring_confirmation_view_model.deposit_information.new_collection_day,
    );
    this.recurring_confirmation_view_model.confirmOperation();
  }

  created() {
    this.recurring_confirmation_view_model.initialize();
  }
}

