






































































































































































































import { Component, Vue } from 'vue-property-decorator';

import AllianzDepositRecurringGoalsDistributionViewModel
  from '@/vue-app/view-models/allianz-dashboard/deposits/recurring_deposits/allianz-deposit-recurring-goals-distribution-view-model';

@Component({
  name: 'RecurringDepositGoalsDistribution',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
})
export default class RecurringDepositGoalsDistribution extends Vue {
  allianz_deposit_recurring_goals_distribution_view_model = Vue.observable(
    new AllianzDepositRecurringGoalsDistributionViewModel(this),
  );

  created() {
    this.allianz_deposit_recurring_goals_distribution_view_model.initialize();
  }
}
