var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"mt-0 h-full",attrs:{"no-gutters":"","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mb-2 mb-md-6"},[_c('h6',{staticClass:"px-5"},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model.translate('title'))+" ")]),_c('p',{staticClass:"text-text pt-2 px-5"},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model.translate('description'))+" ")]),_c('p',{staticClass:"text-text text-center sky-blue--text pt-5"},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model.translate('amount'))+" ")]),_c('h2',{staticClass:"text-center accent--text text-center"},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model.translate( 'balance', { amount: _vm.allianz_deposit_recurring_goals_distribution_view_model .deposit_amount_formatted } ))+" ")])]),_c('div',_vm._l((_vm.allianz_deposit_recurring_goals_distribution_view_model.linked_goals),function(item,index){return _c('v-row',{key:index,staticClass:"justify-center",attrs:{"no-gutters":""}},[_c('v-col',{class:_vm.allianz_deposit_recurring_goals_distribution_view_model
                       .getCustomizedModerateClassForMainVCol(item.is_moderate),attrs:{"cols":"12","md":"10"}},[_c('v-row',{staticClass:"justify-center pt-5",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-none d-md-block d-sm-block",attrs:{"cols":"1"}},[_c('v-img',{attrs:{"src":item.icon_path,"contain":"","max-width":_vm.allianz_deposit_recurring_goals_distribution_view_model
                  .getIconMaxWidth(item.icon),"alt":_vm.allianz_deposit_recurring_goals_distribution_view_model.translate(item.alt)}})],1),_c('v-col',{attrs:{"cols":(item.name === 'unassigned') ? 4:5}},[_c('span',{staticClass:"caption-text"},[_vm._v(" "+_vm._s(item.label)+" "),(item.is_moderate)?[_c('custom-tooltip',{attrs:{"small-tooltip":true,"label":_vm.allianz_deposit_recurring_goals_distribution_view_model
                    .translate('tooltip_moderate')},on:{"update:label":function($event){_vm.$set(_vm.allianz_deposit_recurring_goals_distribution_view_model
                    , "translate('tooltip_moderate')", $event)}}})]:_vm._e()],2)]),(item.name === 'unassigned')?_c('v-col',{attrs:{"cols":"5","md":"5"}},[_c('span',{staticClass:"text-amount"},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model.translate('balance', { amount: _vm.allianz_deposit_recurring_goals_distribution_view_model .getAmountFormatted(item.assigned_amount) } ))+" ")])]):_c('v-col',{attrs:{"cols":"5","md":"4"}},[_c('v-row',[_c('v-icon',{staticClass:"cursor-pointer",attrs:{"color":"inactive-blue"},on:{"click":function($event){_vm.allianz_deposit_recurring_goals_distribution_view_model
                      .decrementAmount(index)}}},[_vm._v(" mdi-minus ")]),_c('v-text-field',{directives:[{name:"money",rawName:"v-money",value:({ prefix: '$', precision: 0 }),expression:"{ prefix: '$', precision: 0 }"}],staticClass:"text-amount mt-3 customized_size_text_field centered-input",class:_vm.allianz_deposit_recurring_goals_distribution_view_model
                              .getCustomizedModerateClassForVTextField(item.is_moderate),attrs:{"spellcheck":"false","flat":"","solo":"","hide-details":"","background-color":_vm.allianz_deposit_recurring_goals_distribution_view_model
                              .getCustomizedModerateBackgroundColorForVTextField(item.is_moderate)},on:{"change":function($event){_vm.allianz_deposit_recurring_goals_distribution_view_model
                                  .changeAssignedAmountField(index)}},model:{value:(item.assigned_amount_field),callback:function ($$v) {_vm.$set(item, "assigned_amount_field", $$v)},expression:"item.assigned_amount_field"}}),_c('v-icon',{staticClass:"cursor-pointer mb-1",attrs:{"color":"inactive-blue"},on:{"click":function($event){_vm.allianz_deposit_recurring_goals_distribution_view_model
              .incrementAmount(index)}}},[_vm._v("mdi-plus ")])],1)],1)],1),(item.name !== 'unassigned')?_c('v-row',{staticClass:"justify-center pb-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"offset-md":"2","cols":"10","md":"10"}},[_c('label',{class:_vm.allianz_deposit_recurring_goals_distribution_view_model
                          .getCustomizedModerateClassForRecommendedAmountLabel(item.is_moderate)},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model .translate('recommended_amount', { amount: _vm.allianz_deposit_recurring_goals_distribution_view_model .getAmountFormatted(item.recommend_amount) }))+" ")]),_c('br'),_c('label',{class:_vm.allianz_deposit_recurring_goals_distribution_view_model
                     .getCustomClass(index)},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model.getCustomMessage( index))+" ")])])],1):_vm._e()],1)],1)}),1),_c('v-row',{staticClass:"mt-2 mt-md-8 mb-md-2",attrs:{"justify":"center"}},[_c('v-checkbox',{attrs:{"color":"sky-blue"},on:{"change":function($event){_vm.allianz_deposit_recurring_goals_distribution_view_model
                    .distributeDepositAmongGoals()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('span',{staticClass:"primary--text ml-1"},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model .translate('distribute'))+" "),(
                    _vm.allianz_deposit_recurring_goals_distribution_view_model
                      .show_from_recommended_amount_message)?[_c('b',[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model .translate('from_recommended_amount'))+" ")])]:[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model .translate('equally'))+" ")]],2)]},proxy:true}]),model:{value:(
                  _vm.allianz_deposit_recurring_goals_distribution_view_model.is_checkbox_active),callback:function ($$v) {_vm.$set(_vm.allianz_deposit_recurring_goals_distribution_view_model, "is_checkbox_active", $$v)},expression:"\n                  allianz_deposit_recurring_goals_distribution_view_model.is_checkbox_active"}})],1),_c('v-row',{staticClass:"mt-2",attrs:{"justify":"center","justify-md":"space-between"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"secondary","large":"","block":"","height":"3.5em"},on:{"click":_vm.allianz_deposit_recurring_goals_distribution_view_model.prevStep}},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model.translate('back'))+" ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{staticClass:"text-body",attrs:{"rounded":"","color":"accent","large":"","block":"","height":"3.5em","disabled":_vm.allianz_deposit_recurring_goals_distribution_view_model.is_disabled},on:{"click":_vm.allianz_deposit_recurring_goals_distribution_view_model.nextStep}},[_vm._v(" "+_vm._s(_vm.allianz_deposit_recurring_goals_distribution_view_model.translate('continue'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }