import TYPES from '@/types';

// Domain
import { DateFormatter } from '@/modules/shared/domain/date-formatters';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class AllianzDepositRecurringResultViewModel {
  @Inject(TYPES.DATE_FORMATTER)
  readonly date_formatter!: DateFormatter;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  i18n_namespace = 'components.allianz-dashboard.deposits.recurring_deposit_result';

  new_day_for_collection = '';

  new_month_for_collection = '';

  new_year_for_collection = '';

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  setCollectionDay = (new_collection_day: number) => {
    const today = new Date();
    const current_month = today.getMonth();
    const next_month = current_month === 11 ? new Date(
      today.getFullYear() + 1, 0, new_collection_day,
    ) : new Date(
      today.getFullYear(), current_month + 1, new_collection_day,
    );
    this.new_day_for_collection = String(next_month.getDate());
    this.new_month_for_collection = this.date_formatter.formatDate(next_month, 'MMMM');
    this.new_year_for_collection = String(next_month.getFullYear());
  }
}
