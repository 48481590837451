import TYPES from '@/types';
import Vue from 'vue';

// Application
import { GetAllianzAccountQuery } from '@/modules/my-investment/allianz/allianz-account/application/queries';
import { ActivateRecurringContributionCommand } from '@/modules/my-investment/allianz/allianz-account/application/commands';
import DistributeRecurrentBalanceCommand
  from '@/modules/flagship/investor-goal/sowos-wealth/distribute-recurrent-balance/application/command/distribute-recurrent-balance-command';

// Domain
import {
  ActivateRecurringContributionEntity,
} from '@/modules/my-investment/allianz/allianz-account/domain/entities/activate-recurring-contribution-entity';
import { DepositRecurringDto } from '@/modules/my-investment/allianz/transaction/domain/dtos/deposit-recurring-dto';
import {
  DistributeRecurrentBalanceDto,
} from '@/modules/flagship/investor-goal/sowos-wealth/distribute-recurrent-balance/domain/dto/distribute-recurrent-balance-dto';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AllianzDepositRecurringConfirmationViewModel {
  @Inject(TYPES.GET_ALLIANZ_ACCOUNT_QUERY)
  private readonly get_allianz_account_query!: GetAllianzAccountQuery;

  @Inject(TYPES.ACTIVATE_RECURRING_CONTRIBUTION_COMMAND)
  private readonly activate_recurring_contribution_command!: ActivateRecurringContributionCommand;

  @Inject(TYPES.DISTRIBUTE_RECURRENT_BALANCE_COMMAND)
  private readonly distribute_recurrent_balance_command!: DistributeRecurrentBalanceCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  private readonly customer_id = sessionStorage.getItem('user_id');

  @Inject(TYPES.NOTIFIER)
  readonly messageNotifier!: MessageNotifier;

  private readonly view: Vue;

  i18n_namespace = 'components.allianz-dashboard.deposits.recurring_contribution_confirm';

  is_loading = false;

  allianz_account_id = '';

  policy_number = '';

  formatted_collection_date = '';

  formatted_new_collection_date = '';

  formatted_new_amount = '';

  deposit_information = {
    source_account: '',
    collection_day: 0,
    amount: '',
    new_collection_day: 0,
    new_amount: '',
    periodicity: '',
  };

  successful_result = false;

  number = 2;

  is_some_goal_active = false;

  payload_to_save_distribution: DistributeRecurrentBalanceDto = {
    total_contribution: '0',
    unassigned_balance: '0',
    investor_goals: [],
  };

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  formatCollectionDay = (collection_day: number) => (collection_day > 0 ? `${this.translate('day')}
    ${collection_day} ${this.translate('of_month')}`
    : this.translate('recurring_not_defined'));

  formatNewCollectionDay = (collection_day: number, new_collection_day: number) => (collection_day !== new_collection_day ? `${this.translate('day')}
    ${new_collection_day} ${this.translate('of_month')}`
    : this.translate('without_changes'));

  formatNewAmount = (amount: string, new_amount: string) => (
    amount !== new_amount ? new_amount : this.translate('without_changes'));

  setDepositInformation = (information: DepositRecurringDto) => {
    this.deposit_information = information;
    this.formatted_collection_date = this.formatCollectionDay(
      information.collection_day,
    );
    this.formatted_new_collection_date = this.formatNewCollectionDay(
      information.collection_day, information.new_collection_day,
    );
    this.formatted_new_amount = this.formatNewAmount(information.amount, information.new_amount);
  }

  confirmOperation = async () => {
    this.is_loading = true;
    const body: ActivateRecurringContributionEntity = {
      id: this.allianz_account_id,
      // eslint-disable-next-line radix
      monthly_collection_day: this.deposit_information.new_collection_day,
      amount: parseFloat(this.deposit_information.new_amount
        .replace(/[^0-9.]/g, '')).toFixed(2),
      periodicity: this.deposit_information.periodicity,
      confirm_periodicity: true,
      home_desire: true,
    };
    try {
      await this.activate_recurring_contribution_command.execute(body);
      const allianz_account = await this.get_allianz_account_query.execute(
        { customer_id: this.customer_id, reload: true },
      );
      if (this.is_some_goal_active) {
        await this.saveAmountDistributedAmongLinkedGoals();
      }
      const recurring_contributions_updated_event = new CustomEvent('allianz.update.recurring.contribution', { detail: { ...allianz_account } });
      window.dispatchEvent(recurring_contributions_updated_event);
      this.successful_result = true;
      this.nextStep();
    } catch {
      this.messageNotifier.showErrorNotification(
        this.translate('errors.on_update_recurring_deposit'),
      );
      this.successful_result = false;
    } finally {
      this.is_loading = false;
    }
  }

  setPayloadToSaveDistribution = (payload: DistributeRecurrentBalanceDto) => {
    this.payload_to_save_distribution = payload;
    this.is_some_goal_active = true;
  }

  saveAmountDistributedAmongLinkedGoals = async () => {
    try {
      // eslint-disable-next-line max-len
      await this.distribute_recurrent_balance_command.execute(this.payload_to_save_distribution);
    } catch {
      this.messageNotifier.showErrorNotification(this.translate('errors.distribute_recurrent_balance_command'));
    }
  }

  nextStep = () => {
    this.view.$emit('nextStep', this.deposit_information.new_collection_day);
  }

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  initialize = async () => {
    const allianz_account = await this.get_allianz_account_query.execute(
      { customer_id: this.customer_id },
    );
    this.allianz_account_id = allianz_account.id;
    this.policy_number = allianz_account.policy_number_issued;
  }
}
